.offer {
    .container {
        display: flex;
        align-items: flex-end;
        padding-bottom: 100px;
        .line_holder {
            .line {
                background: #e8e8e8;
            }
            .line:not(:first-of-type) {
                opacity: 0;
            }
        }
        .left {
            width: 50%;
            box-sizing: border-box;
            padding-right: 55px;
            padding-bottom: 170px;
            h2 {
                padding-bottom: 60px;
                text-align: left;
            }
            p {
                padding-bottom: 40px;
                line-height: 26px;
                color: #000;
            }
        }
        .bottom {
            position: absolute;
            left: 0;
            bottom: 120px;
            hr {
                border-bottom-color: rgba(0,0,0,1);
                margin-bottom: 30px;
            }
            .accent {
                font-weight: bold;
                font-size: 18px;
                color: #000;
                padding-bottom: 40px;
            }
            .link {
                color: #3b6eaa;
                font-size: 15px;
                letter-spacing: .04em;
            }
        }
        .right {
            background: #f1f8fc;
            width: 50%;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: -72px;
            transform-style: preserve-3d;
            border: 1px solid #eee;
            position: relative;
            overflow: hidden;
            transition: all 0.5s;
            &.active {
                box-shadow: -1px 29px 33px 0px rgba(0, 0, 0, 0.14);
                #license {
                    right: 0;
                }
            }
            .title {
                width: 100%;
                height: 72px;
                background: #3b6eaa;
                display: flex;
                align-items: center;
                padding-left: 80px;
                box-sizing: border-box;
                font-size: 16px;
                color: #fff;
                text-transform: uppercase;
                border-radius: 5px 5px 0 0 ;
                box-shadow: 0 17px 19px 0 rgba(23, 28, 82, 0.13);
            }
            .body {
                padding: 65px 80px 45px 80px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                ul {
                    li {
                        margin-bottom: 25px;
                        display: flex;
                        align-items: center;
                        color: #000;
                        &:before {
                            content: '';
                            min-width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            border: 1px solid #000;
                            margin-right: 20px;
                            display: flex;
                        }
                    }
                }
                hr {
                    margin: 20px 0;
                    border-color: rgba(0,0,0,.1);
                    width: 100%;
                }
                p {
                    font-weight: bold;
                    font-size: 15px;
                    color: #141414;
                    margin: 0 auto;
                    text-align: center;
                }
                .price {
                    font-weight: normal;
                    color: #141414;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    margin: 10px 0;
                    width: 100%;
                    span {
                        color: #fa4e37;
                        font-family: 'ElektraTextPro-Bold';
                        margin-right: 10px;
                        span {
                            font-size: 40px;
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
                .buttons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    &>.button:not(.small) {
                        margin: 30px 0 0 0;
                        width: 48%;
                        box-sizing: border-box;
                        padding: 0;
                        text-align: center;
                    }
                }
                &>.button:not(.small) {
                    margin: 30px auto 0 auto;
                }
            }
        }
        #license {
            position: absolute;
            top: 0;
            right: 100%;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 1;
            border-radius: 5px;
            transition: all 0.5s;
            .title {
                background: $osn-col1;
                position: relative;
                img {
                    position: absolute;
                    left: 2%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 40%;
                    filter: invert(1);
                    cursor: pointer;
                }
            }
            .body {
                padding-top: 30px;
                ul {
                        li {
                                &:last-of-type {
                                        &:before {
                                                opacity: 0;
                                        }
                                }
                        }
                }
                .small {
                    margin: 0 auto;
                }
                p {
                    font-size: 20px;
                }
                .price {
                    color: $osn-col1;
                    font-size: 45px;
                    margin: 0;
                    span {
                        font-size: 75px;
                        color: $osn-col1;
                    }
                }
                hr {
                    border-bottom-color: rgba(0,0,0,.1);
                    width: 100%;
                }
            }
        }
    }
}


@media screen and (max-width: $mobi_start) {
    .offer {
        .container {
            flex-direction: column;
            padding-bottom: 0 !important;
            .left {
                width: 100%;
                padding-right: 0;
                padding-bottom: 0;
                .link {
                    font-size: $m10;
                }
            }
            .bottom {
                position: static;
                padding-bottom: $m30;
                hr {
                    margin-bottom: $m20;
                    margin-top: $m20;
                }
                p {
                    padding-bottom: $m30;
                }
            }
            .right {
                margin-top: 0;
                width: 100%;
                .title {
                    font-size: $m10 !important;
                    height: $m30;
                    padding-bottom: 0 !important;
                    border-radius: $m3 $m3 0 0;
                    padding-left: $m20;
                }
                .body {
                    padding: $m20;
                    ul {
                        li {
                            margin-bottom: $m10;
                            &:before {
                                min-width: $m3;
                                height: $m3;
                                margin-right: $m10;
                            }
                            font-size: $m10;
                        }
                    }
                    hr {
                        margin: $m10 0;
                    }
                    p {
                        padding-right: 0 !important;
                    }
                    .price {
                        font-size: $m10;
                        margin: $m15 0;
                        span {
                            margin-right: $m3;
                            span {
                                font-size: $m25;
                                margin-left: $m3;
                            }
                        }
                    }
                }
            }
        }
    }
}
