.partner {
    padding-top: 112px;
    padding-bottom: 200px;
    position: relative;
    background: url(/img/index_new/partner_bg.png);
    z-index: 1;
    .container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        .line_holder {
            .line:last-of-type {
                opacity: 0;
            }
        }
    }
    h2 {
        padding-bottom: 50px;
        color: #fff;
    }
    .logo {
        position: absolute;
        height: 60%;
        top: 100px;
        bottom: 0;
        right: calc(50% - 41%);
        margin: auto;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        z-index: 2;
        div {
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
            cursor: pointer;
            padding: 20px 0;
            border-radius: 5px;
            transition: all 0.5s;
            img {
                filter: grayscale(1);
                max-width: 100px;
                transition: all 0.5s;
                &.invert {
                    filter: grayscale(1) invert(1);
                    &.important {
                        filter: grayscale(1) invert(1) !important;
                    }
                }
            }
            &.small {
                img {
                    max-width : 80px;
                }
            }
            &.active {
                img {
                    filter: none !important;
                }
            }
        }
    }
    .arrow {
        background-image: linear-gradient( 90deg, $osn-col2 0%, $osn-col2 100%);
        box-shadow: 0px 8px 11px 0px rgba(0, 0, 0, 0.41);
        width: 105px;
        height: 36px;
        border-radius: 2px;
        position: absolute;
        top: 230px;
        left: 0;
        right: 540px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        z-index: 10;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 1px;
            height: 60%;
            background: #fff;
            opacity: .3;
        }
        .next, .prev {
            width: 50%;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 20px;
                filter: invert(1);
            }
        }
        .next {
            padding-left: 15px;
            &:hover {
                animation: arrow 1s forwards infinite alternate;
            }
        }
        .prev {
            padding-right: 15px;
            &:hover {
                animation: arrow_reverse 1s forwards infinite alternate;
            }
        }
    }
    .partnerSlider {
        width: 100%;
        z-index: 1;
        .slick-track {
            padding-bottom: 117px;
            display: flex;
            align-items: stretch;
            transition: margin 0.5s ease;
        }
        .item {
            padding: 56px 32px;
            width: 361px;
            height: auto;
            box-sizing: border-box;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 35px 51px 0 rgba(0, 0, 0, 0.1);
            position: relative;
            top: 40px;
            transform: scale(.83) translateX(-30px);
            margin-left: -20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            transition: all 0.5s;
            &.slick-center {
                transform: none;
                top: 0;
                left: 0;
                &~.item {
                    opacity: 0;
                }
            }
            img {
                max-height: 78px;
                max-width: 100px;
                margin-bottom: 36px;
            }
            .title {
                font-weight: bold;
                font-size: 18px;
                color: #000;
                margin-bottom: 10px;
            }
            .text {
                line-height: 28px;
                color: #000;
                height : 250px;
                overflow : auto;
                padding-right : 25px;
            }
            .after {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: auto;
                position: absolute;
                bottom: 32px;
                right: 32px;
                font-weight: bold;
                color: #808080;
            }
        }
    }
}

@media screen and (max-width: $mobi_start) {
    .partner {
        padding-top: $m30;
        padding-bottom : 0;
        .container {
            left: 0;
            right: 0;
            .line_holder {
                .line:nth-of-type(2n-1) {
                    background: rgba(255,255,255,.1);
                }
            }
        }
        h2 {
            padding-bottom: 0 !important;
        }
        .logo {
            display: none;
        }
        .holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            margin: 0 auto;
        }
        .arrow {
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
            width: $m80;
            height: $m30;
            box-shadow: 0 $m4 $m5 0 rgba(0,0,0,0.41);
            .next, .prev {
                img {
                    width: $m15;
                }
            }
            .next {
                padding-left: $m10;
            }
            .prev {
                padding-right: $m10;
            }
        }
        .partnerSlider {
            margin-top: $m30;
            .slick-track {
                padding-bottom: $m30;
            }
            .item {
                width: $m280;
                height: auto;
                padding: $m30 $m20;
                img {
                    margin-bottom: $m20;
                }
                p {
                    padding-right: 0 !important;
                }
                .after {
                    position: static;
                    margin-top: auto;
                    padding-top: $m20;
                }
            }
        }
    }
}
