footer {
  z-index: 2;
  position: relative;
  background: $dark;
  a {
    transition: all 0.5s;
    &:hover {
      color: $osn-col3 !important;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 80px 0;
    .contacts {
      .logo {
        width: 200px;
        margin-bottom: 30px;
        display: flex;
        img {
          width: 100%;
        }
      }
      a:not(.logo) {
        display: flex;
        align-items: center;
        text-decoration: underline;
        font-weight: bold;
        letter-spacing: .04em;
        margin-bottom: 20px;
        white-space: pre-line;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
      a.address {
        text-decoration: none;
      }
      .social {
        display: flex;
        a {
          margin-right: 20px;
          position: relative;
          img {
            width: auto;
            height: 30px;
            margin-right: 0;
            transition: all 0.5s;
          }
          .hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0;
            z-index: 1;
          }
          &:hover {
            img {
              opacity: 0;
            }
            .hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .links {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      &:nth-of-type(2) {
        border-left: 1px solid rgba(255,255,255,.1);
        padding-left: 50px;
        margin-left: 50px;
      }
      .title {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        padding-bottom: 35px;
      }
      ul {
        &:nth-of-type(1) {
          margin-right: 30px;
        }
        li {
          margin-bottom: 20px;
          a {
            font-size: 16px;
            text-transform : capitalize;
          }
        }
      }
    }
  }
  .under {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    border-top: 1px solid rgba(255,255,255,.1);
    .container {
      padding: 0;
    }
    .left {
      display: flex;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        color: #c8c8c8;
        text-decoration: underline;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    .right {
      ul {
        display: flex;
        li {
          a {
            border-radius: 50px;
            transition: all 0.5s;
            padding: 0 15px;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            cursor: pointer;
            letter-spacing: .04em;
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobi_start) {
  footer {
    .container {
      .right, .links {
        display: none;
      }
      .contacts {
        .logo {
          width: $m150;
          align-items: center;
          margin-bottom : $m20;
        }
        a:not(.logo) {
          margin-bottom: $m10;
        }
        .social {
          a {
            margin-right: $m10;
            margin-bottom: 0;
          }
        }
      }
    }
    .under {
      height: $m30;
      .container {
        padding: 0 !important;
      }
    }
  }
}
